import { GridRowId } from "@mui/x-data-grid";
import { ResponseType } from "axios";

import {
  batchConfigUpdate,
  clientConfigUpdate,
  feeSplitConfigUpdate,
  globalConfigUpdate,
  inputOutputType,
  normalExpenseUpdate,
  specialExpenseUpdate,
  timeMappingUpdate,
} from "../@types/assetTypes/ap";

import { HTTP } from ".";

export interface initiateInvoicesProps {
  includedInvoices: InvoicesProps;
  excludedInvoices: InvoicesProps;
}

export interface InvoicesProps {
  ranges?: Array<{
    startInvoiceId: number;
    endInvoiceId: number;
  }>;
  invoiceNumbers?: [] | number[];
}

export interface initiateARvmsInvoicesProps {
  vms?: { id?: string; name?: string };
  filename?: string | null;
}

const { host } = window.location;

let environment =
  (host.includes("localhost") && "local") ||
  (host.includes("vmsops-dev") && "dev") ||
  (host.includes("vmsops-qa") && "qa") ||
  (host.includes("vmsops-preprod") && "staging") ||
  (host.includes("vmsops.mbopartners") && "prod");

environment = environment.toString().toUpperCase();

const BaseUrl = (): string => {
  const host = process.env[`REACT_APP_${environment || ""}_API_BASEURL`];

  return host || "";
};

export default {
  getDownloadFile(runID: GridRowId, type?: inputOutputType) {
    const responseTypeVal: ResponseType = "blob";

    let url = `${BaseUrl()}/processData/${runID}`;

    if (type === "input") url += `/vmsInputFile`;
    else if (type === "invoicepdf") url += `/invoicepdf`;
    else url += `/file`;

    return {
      url,
      options: {
        data: {},
        method: HTTP.GET,
        headers: {},
        responseType: responseTypeVal,
      },
    };
  },
  getDropdownList() {
    return {
      url: `${BaseUrl()}/ar/vmsDetails`,
      options: {
        data: {},
        method: HTTP.GET,
        headers: {},
      },
    };
  },
  getClientList(vmsName?: string) {
    let url = `${BaseUrl()}/clients?filter=company_type in (Client)`;

    if (vmsName) url += `&filter=VMS_NAME in (${vmsName})`;

    return {
      url,
      options: {
        data: {},
        method: HTTP.GET,
        headers: {},
      },
    };
  },
  getBatchList(p?: { isFilterEnabled?: boolean }) {
    let url = `${BaseUrl()}/invoicePresentation/config/batches`;

    if (p?.isFilterEnabled) url += "?filter=enabled in (true)";

    return {
      url,
      options: {
        data: {},
        method: HTTP.GET,
        headers: {},
      },
    };
  },
  getTemplateList() {
    const url = `${BaseUrl()}/invoicePresentation/config/templates`;

    return {
      url,
      options: {
        data: {},
        method: HTTP.GET,
        headers: {},
      },
    };
  },
  getConditionCodes() {
    return {
      url: `${BaseUrl()}/attributes`,
      options: {
        data: {},
        method: HTTP.GET,
        headers: {},
      },
    };
  },
  getClientConditionCodes(s4CompanyId: string) {
    return {
      url: `${BaseUrl()}/clients/${s4CompanyId}/officeFeeConfig`,
      options: {
        data: {},
        method: HTTP.GET,
        headers: {},
      },
    };
  },
  getUnpaidTalents(
    sortValue: string,
    sortOrder: string,
    f: {
      fId?: string;
      fEmpName?: string;
      fEmpCompany?: string;
      fEmpCategory?: string;
      fInvoiceType?: string;
      fSowId?: string;
      fVendorId?: string;
      fEmailId?: string;
      fStatus?: string;
    },
    pageInfo: { pageNo: number; pageSize: number },
  ) {
    const { pageNo, pageSize } = pageInfo;

    let url = `${BaseUrl()}/ap/invoices?sort=${sortValue}&sortOrder=${sortOrder}&page=${pageNo}&page_size=${pageSize}`;

    if (f?.fId) url += `&filter=${f?.fId}`;
    if (f?.fEmpName) url += `&filter=${f?.fEmpName}`;
    if (f?.fEmpCompany) url += `&filter=${f?.fEmpCompany}`;
    if (f?.fEmpCategory) url += `&filter=${f?.fEmpCategory}`;
    if (f?.fInvoiceType) url += `&filter=${f?.fInvoiceType}`;
    if (f?.fSowId) url += `&filter=${f?.fSowId}`;
    if (f?.fVendorId) url += `&filter=${f?.fVendorId}`;
    if (f?.fEmailId) url += `&filter=${f?.fEmailId}`;
    if (f?.fStatus) url += `&filter=${f?.fStatus}`;

    return {
      url,
      options: {
        data: {},
        method: HTTP.GET,
        headers: {},
      },
    };
  },
  getRunHistory(
    process: string,
    sortValue: string,
    sortOrder: string,
    f: {
      fId?: string;
      fInvoicesIncluded?: string;
      fStatus?: string;
      fFileName?: string;
      fVMS?: string;
      fClients?: string;
      fStartedAt?: string;
      fStartedBy?: string;
    },
    pageInfo: { pageNo: number; pageSize: number },
  ) {
    const { pageNo, pageSize } = pageInfo;

    let url = `${BaseUrl()}/${process}/processExecutionDetail?sort=${sortValue}&sortOrder=${sortOrder}&page=${pageNo}&page_size=${pageSize}`;

    if (f?.fId) url += `&filter=${f?.fId}`;
    else if (f?.fInvoicesIncluded) url += `&filter=${f?.fInvoicesIncluded}`;
    else if (f?.fStatus) url += `&filter=${f?.fStatus}`;
    else if (f?.fFileName) url += `&filter=${f?.fFileName}`;
    else if (f?.fVMS) url += `&filter=${f?.fVMS}`;
    else if (f?.fClients) url += `&filter=${f?.fClients}`;
    else if (f?.fStartedAt) url += `&filter=${f?.fStartedAt}`;
    else if (f?.fStartedBy) url += `&filter=${f?.fStartedBy}`;

    return {
      url,
      options: {
        data: {},
        method: HTTP.GET,
        headers: {},
      },
    };
  },
  getStatusUpdate(
    sortValue: string,
    sortOrder: string,
    f: {
      fId?: string;
      fStartedBy?: string;
      fStatus?: string;

      fStartedAt?: string;
    },
    pageInfo: { pageNo: number; pageSize: number },
  ) {
    const { pageNo, pageSize } = pageInfo;

    let url = `${BaseUrl()}/talex/statusUpdate?sort=${sortValue}&sortOrder=${sortOrder}&page=${pageNo}&page_size=${pageSize}`;

    if (f?.fId) url += `&filter=${f?.fId}`;
    else if (f?.fStatus) url += `&filter=${f?.fStatus}`;
    else if (f?.fStartedAt) url += `&filter=${f?.fStartedAt}`;
    else if (f?.fStartedBy) url += `&filter=${f?.fStartedBy}`;

    return {
      url,
      options: {
        data: {},
        method: HTTP.GET,
        headers: {},
      },
    };
  },
  getDetailRecord(
    runID: GridRowId,
    f: {
      fIssues?: string;
      fRemarks?: string;
    },
    pageInfo?: { pageNo: number; pageSize: number },
  ) {
    const { pageNo, pageSize } = pageInfo || {};

    const queryStrings = [
      typeof pageNo === "number" ? `page=${pageNo}` : "",
      typeof pageSize === "number" ? `page_size=${pageSize}` : "",
      f?.fIssues ? `filter=${f?.fIssues}` : "",
      f?.fRemarks ? `filter=${f?.fRemarks}` : "",
    ]
      .filter(Boolean)
      .join("&");

    const url = [`${BaseUrl()}/process/${runID}/records`, queryStrings]
      .filter(Boolean)
      .join("?");

    return {
      url,
      options: {
        data: {},
        method: HTTP.GET,
        headers: {},
      },
    };
  },
  InitiateApInvoices(data: initiateInvoicesProps) {
    return {
      url: `${BaseUrl()}/ap/s4file`,
      options: {
        data,
        method: HTTP.POST,
        header: {},
      },
    };
  },
  saveClientConditionCodes(data: {
    s4CompanyId: string;
    companyName: string;
    mboFeePricingAttributes: string;
    lastUpdateBy: string;
  }) {
    return {
      url: `${BaseUrl()}/clients/${data.s4CompanyId}/officeFeeConfig`,
      options: {
        data,
        method: HTTP.PUT,
        header: {},
      },
    };
  },
  reInitiateApInvoices(runID: GridRowId) {
    return {
      url: `${BaseUrl()}/ap/rerun/${runID}`,
      options: {
        method: HTTP.POST,
        header: {},
      },
    };
  },
  InitiateArNonVMSInvoices(data: initiateInvoicesProps) {
    return {
      url: `${BaseUrl()}/ar/nonvms/s4file`,
      options: {
        data,
        method: HTTP.POST,
        header: {},
      },
    };
  },
  reInitiateArNonVMSInvoice(runID: GridRowId) {
    return {
      url: `${BaseUrl()}/ar/nonVMS/rerun/${runID}`,
      options: {
        method: HTTP.POST,
        headers: {},
      },
    };
  },
  InitiateARvmsInvoice(data: FormData) {
    return {
      url: `${BaseUrl()}/ar/vms/s4File`,
      options: {
        data,
        method: HTTP.POST,
        headers: { "Content-Type": "multipart/form-data" },
      },
    };
  },
  reInitiateARvmsInvoice(runID: GridRowId) {
    return {
      url: `${BaseUrl()}/ar/vms/rerun/${runID}`,
      options: {
        method: HTTP.POST,
        headers: {},
      },
    };
  },
  validatePwCInvoices(
    toDate: string,
    invoiceType?: string,
    mockValidation?: boolean,
    fromDate?: string | null,
    batchId?: string | null,
  ) {
    const invoiceTypeFilter = invoiceType
      ? `filter=invoiceType in (${invoiceType})&`
      : "";

    const mockValidationFilter = mockValidation
      ? `filter=mockValidation in (${mockValidation ? "ON" : "OFF"})&`
      : "";

    const toDateFilter = `filter=toDate in (${toDate})`;
    const fromDateFilter = fromDate ? `&filter=fromDate in (${fromDate})` : "";
    const batchID = batchId ? `&filter=BatchID in (${batchId})` : "";

    return {
      url: `${BaseUrl()}/talex/validateInvoice?${invoiceTypeFilter}${mockValidationFilter}${toDateFilter}${fromDateFilter}${batchID}`,
      options: {
        data: {},
        method: HTTP.GET,
        headers: {},
      },
    };
  },

  genaratePwCInvoices(toDate: string, invoiceType?: string) {
    const invoiceTypeFilter = invoiceType
      ? `filter=invoiceType in (${invoiceType})&`
      : "";

    const invoiceFileDate = `filter=invoiceFileDate in (${toDate})`;

    return {
      url: `${BaseUrl()}/talex/generateInvoice?${invoiceTypeFilter}${invoiceFileDate}`,
      options: {
        data: {},
        method: HTTP.GET,
        headers: {},
      },
    };
  },
  importJDFiles() {
    return {
      url: `${BaseUrl()}/talex/processor`,
      options: {
        data: {},
        method: HTTP.GET,
        headers: {},
      },
    };
  },

  importNormalExpenses(
    method: string,
    putData?: normalExpenseUpdate | null,
    deleteData?: { expenseId: number[] },
  ) {
    return {
      url: `${BaseUrl()}/expenseDetail`,
      options: {
        data:
          method === "PUT" ? putData : method === "DELETE" ? deleteData : {},
        method:
          method === "GET"
            ? HTTP.GET
            : method === "PUT"
              ? HTTP.PUT
              : HTTP.DELETE,
        headers: {},
      },
    };
  },

  importSpecialExpenses(
    method: string,
    putData?: specialExpenseUpdate | null,
    deleteData?: { specialExpenseId: number[] },
  ) {
    return {
      url: `${BaseUrl()}/specialExpenseDetail`,
      options: {
        data:
          method === "PUT" ? putData : method === "DELETE" ? deleteData : {},
        method:
          method === "GET"
            ? HTTP.GET
            : method === "PUT"
              ? HTTP.PUT
              : HTTP.DELETE,
        headers: {},
      },
    };
  },

  importTimeMapping(
    method: string,
    putData?: timeMappingUpdate | null,
    deleteData?: { timeIds: number[] },
  ) {
    return {
      url: `${BaseUrl()}/timeDetail`,
      options: {
        data:
          method === "PUT" ? putData : method === "DELETE" ? deleteData : {},
        method:
          method === "GET"
            ? HTTP.GET
            : method === "PUT"
              ? HTTP.PUT
              : HTTP.DELETE,
        headers: {},
      },
    };
  },

  importClientConfig(
    method: string,
    putData?: clientConfigUpdate | null,
    deleteData?: { configs: [{ clientId: string; key: string }] } | null,
    clientId?: string,
  ) {
    return {
      url: `${BaseUrl()}/clientConfig${
        clientId ? `?filter=CLIENT_ID in (${clientId})` : ""
      }`,
      options: {
        data:
          method === "PUT" ? putData : method === "DELETE" ? deleteData : {},
        method:
          method === "GET"
            ? HTTP.GET
            : method === "PUT"
              ? HTTP.PUT
              : HTTP.DELETE,
        headers: {},
      },
    };
  },

  updateBatch(data: batchConfigUpdate | null) {
    return {
      url: `${BaseUrl()}/invoicePresentation/config/batch`,
      options: {
        data: data || {},
        method: "PUT",
        headers: {},
      },
    };
  },

  deleteBatch(batchId: string) {
    return {
      url: `${BaseUrl()}/invoicePresentation/config/batch/${batchId}`,
      options: {
        data: {},
        method: "DELETE",
        headers: {},
      },
    };
  },

  batchTemplateFeeSplitConfig(
    method: string,
    putData?: feeSplitConfigUpdate | null,
    clientId?: string,
  ) {
    let url = `${BaseUrl()}/invoicePresentation/config`;

    if (method === "GET") url += `/clients/${clientId}`;
    else if (method === "PUT") url += `/client`;

    return {
      url,
      options: {
        data: method === "PUT" ? putData : {},
        method: method === "GET" ? HTTP.GET : method === "PUT" ? HTTP.PUT : "",
        headers: {},
      },
    };
  },

  feeSplitConfig(
    method: string,
    putData?: feeSplitConfigUpdate | null,
    feeSplitId?: string | number,
    clientId?: string,
  ) {
    let url = `${BaseUrl()}/invoicePresentation/config`;

    if (method === "PUT") url += `/client/feeSplit`;
    else if (method === "DELETE")
      url += `/client/${clientId}/feesplit/${feeSplitId}`;

    return {
      url,
      options: {
        data: method === "PUT" ? putData : {},
        method:
          method === "PUT" ? HTTP.PUT : method === "DELETE" ? HTTP.DELETE : "",
        headers: {},
      },
    };
  },

  InitiateAdhocInvoices(data: initiateInvoicesProps) {
    return {
      url: `${BaseUrl()}/invoicePresentation/adhocInvoices`,
      options: {
        data,
        method: HTTP.POST,
        header: {},
      },
    };
  },

  getConfigMetadata(level: string) {
    return {
      url: `${BaseUrl()}/configMetadata?filter=level in (${level})`,
      options: {
        data: {},
        method: HTTP.GET,
        headers: {},
      },
    };
  },

  importGlobalConfig(
    method: string,
    putData?: globalConfigUpdate | null,
    deleteData?: { configId: number[] } | null,
  ) {
    return {
      url: `${BaseUrl()}/globalConfig`,
      options: {
        data:
          method === "PUT" ? putData : method === "DELETE" ? deleteData : {},
        method:
          method === "GET"
            ? HTTP.GET
            : method === "PUT"
              ? HTTP.PUT
              : HTTP.DELETE,
        headers: {},
      },
    };
  },

  uploadEBDR(data: FormData) {
    return {
      url: `${BaseUrl()}/ebdr/bdr`,
      options: {
        data,
        method: HTTP.POST,
        headers: { "Content-Type": "multipart/form-data" },
      },
    };
  },

  getS4RunHistory(
    sortValue: string,
    sortOrder: string,
    filter: string,
    fInvoices: string,
    fSourceInvoices: string,
    fStatus: string,
    pageInfo: { pageNo: number; pageSize: number },
  ) {
    const { pageNo, pageSize } = pageInfo;

    let url = `${BaseUrl()}/ebdr/runDetails?sort=${sortValue}&sortOrder=${sortOrder}&page=${pageNo}&page_size=${pageSize}`;

    if (filter) url += `&filter=${filter}`;
    if (fInvoices) url += `&filter=${fInvoices}`;
    if (fSourceInvoices) url += `&filter=${fSourceInvoices}`;
    if (fStatus) url += `&filter=${fStatus}`;

    return {
      url,
      options: {
        data: {},
        method: HTTP.GET,
        headers: {},
      },
    };
  },

  getS4RunHistoryDetails(runId: string) {
    return {
      url: `${BaseUrl()}/ebdr/runDetails/${runId}`,
      options: {
        data: {},
        method: HTTP.GET,
        headers: {},
      },
    };
  },

  deleteBDRs(id: number) {
    return {
      url: `${BaseUrl()}/ebdr/bdr`,
      options: {
        data: { ebdrDetail: { id } },
        method: HTTP.DELETE,
        headers: {},
      },
    };
  },

  createInvoice(id: number) {
    return {
      url: `${BaseUrl()}/ebdr/invoices`,
      options: {
        data: { ebdrDetail: { id } },
        method: HTTP.POST,
        headers: {},
      },
    };
  },

  getDownloadVMSFile(id: number) {
    const responseTypeVal: ResponseType = "blob";

    return {
      url: `${BaseUrl()}/processData/${id}/file?filter=process in (s4)&filter=type in (vms)`,
      options: {
        data: {},
        method: HTTP.GET,
        headers: {},
        responseType: responseTypeVal,
      },
    };
  },

  getDownloadEBDRFile(id: number) {
    const responseTypeVal: ResponseType = "blob";

    return {
      url: `${BaseUrl()}/processData/${id}/file?filter=process in (s4)&filter=type in (ebdr)`,
      options: {
        data: {},
        method: HTTP.GET,
        headers: {},
        responseType: responseTypeVal,
      },
    };
  },
  getBatchIds() {
    return {
      url: `${BaseUrl()}/batches`,
      options: {
        data: {},
        method: HTTP.GET,
        headers: {},
      },
    };
  },
  submitIdsToGenerateInvoice(id: string) {
    return {
      url: `${BaseUrl()}/invoice/pdf?${id}`,
      options: {
        data: {},
        method: HTTP.GET,
        headers: {},
      },
    };
  },
};
